export const environment = {
  AUTH_BACKEND: process.env.REACT_APP_AUTH_BACKEND,
  WORKSPACE_BACKEND: process.env.REACT_APP_WORKSPACE_BACKEND,
  ARTICLE_BACKEND: process.env.REACT_APP_ARTICLE_BACKEND,
  API_URL: process.env.REACT_APP_API_URL,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  DEVIS_BACKEND: process.env.REACT_APP_DEVIS_BACKEND,
  INVOICE_BACKEND: process.env.REACT_APP_INVOICE_BACKEND,
  CLIENT_BACKEND: process.env.REACT_APP_CLIENT_BACKEND,
};
