import { CreateDevisPayload } from "modules/devis/types/devis.interface";
import { useGetWorkspaceStaff } from "modules/workspaces/api";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";

interface SelectIntervenantsProps {
  formValues: CreateDevisPayload;
  setFormValues: (values: CreateDevisPayload) => void;
}

export const SelectIntervenants: FunctionComponent<SelectIntervenantsProps> = ({
  formValues,
  setFormValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: staff } = useGetWorkspaceStaff();

  const options = useMemo(() => {
    return staff?.map((member) => ({
      id: member.id,
      name: member.name,
    })) || [];
  }, [staff]);

  const handleOptionChange = (optionValue: Option) => {
    const isOptionSelected = formValues.interveners.includes(optionValue.id);
    setFormValues({
      ...formValues,
      interveners: isOptionSelected
        ? formValues.interveners.filter((i) => i !== optionValue.id)
        : [...formValues.interveners, optionValue.id],
    });
  };

  useEffect(() => {
    if (searchValue === "" && formValues.interveners.length > 0) {
      const interveners = formValues.interveners.map((intervener) => {
        return options.find((option) => option.id === intervener);
      });

      setSearchValue(
        interveners.map((intervener) => intervener?.name).join(", ")
      );
    }
  }, [formValues.interveners, options, searchValue]);

  if (!options && options.length < 1) return;

  return (
    <Autocomplete
      options={options}
      onSelect={(option) => handleOptionChange(option)}
      value={formValues.interveners}
      label="Sélectionner un Intervenant"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      isMultiple
    />
  );
};
