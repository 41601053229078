import { CreateDevisPayload } from "modules/devis/types/devis.interface";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";
import { useGetClients } from "modules/article/api";

interface SelectIntervenantsProps {
  formValues: CreateDevisPayload;
  setFormValues: (values: CreateDevisPayload) => void;
}

export const SelectClient: FunctionComponent<SelectIntervenantsProps> = ({
  formValues,
  setFormValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: clients } = useGetClients(searchValue);
  const flatClients = clients?.pages?.map((page) => page.data).flat();

  const options = useMemo(() => {
    return (
      flatClients?.map((client) => ({
        id: client._id,
        name: client.name,
      })) || []
    );
  }, [flatClients]);

  const handleOptionChange = (optionValue: Option) => {
    setFormValues({
      ...formValues,
      clientId: optionValue.id,
    });
  };

  useEffect(() => {
    if (searchValue === "" && formValues.clientId) {
      const client = options.find(
        (option) => option.id === formValues.clientId
      );
      setSearchValue(client?.name || "");
    }
  }, [formValues.clientId, options, searchValue]);

  if (!options && options.length < 1) return;

  return (
    <Autocomplete
      options={options}
      onSelect={(option) => handleOptionChange(option)}
      value={[formValues.clientId]}
      label="Sélectionner un client*"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
