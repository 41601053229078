import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import { InputField } from "components/fields/InputField";
import { useCreateClient } from "modules/client/api";
import { CreateClientPayload } from "modules/client/types/client.interface";
import { FormEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";

const CreateClient = () => {
  const { mutate } = useCreateClient();
  const { formValues, setFormValues } = useCustomForm<CreateClientPayload>({
    name: "",
    email: [],
    phoneNumber: "",
    address: "",
    postalCode: "",
    city: "",
    siret: "",
    codeNaf: "",
    tvaCode: "",
  });

  const isSubmitDisabled =
    formValues.name === "" ||
    formValues.address === "" ||
    formValues.postalCode === "" ||
    formValues.city === "" ||
    formValues.siret === "" ||
    formValues.tvaCode === "";

  const handleSubmit = (e: FormEvent) => {
    if (isSubmitDisabled) return;

    e.preventDefault();

    mutate({
      ...formValues,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Information du client">
        <InputField
          variant="auth"
          extra="mb-3"
          label="Nom du client*"
          placeholder="Nom du client"
          id="name"
          type="text"
          value={formValues.name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              name: e.target.value,
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="email@example.com"
          id="email"
          type="email"
          value={formValues.email[0].value}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              email: [{ key: "email", value: e.target.value }],
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Numéro de téléphone"
          placeholder="0123456789"
          id="phoneNumber"
          type="text"
          value={formValues.phoneNumber}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              phoneNumber: e.target.value,
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Adresse*"
          placeholder="123 Rue Exemple"
          id="address"
          type="text"
          value={formValues.address}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              address: e.target.value,
            })
          }
        />
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Code postal*"
            placeholder="75001"
            id="postalCode"
            type="text"
            value={formValues.postalCode}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                postalCode: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Ville*"
            placeholder="Paris"
            id="city"
            type="text"
            value={formValues.city}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                city: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="SIRET*"
            placeholder="12345678901234"
            id="siret"
            type="text"
            value={formValues.siret}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                siret: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Code NAF*"
            placeholder="1234Z"
            id="codeNaf"
            type="text"
            value={formValues.codeNaf}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                codeNaf: e.target.value,
              })
            }
          />
        </div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Numéro de TVA*"
          placeholder="FR12345678901"
          id="tvaCode"
          type="text"
          value={formValues.tvaCode}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              tvaCode: e.target.value,
            })
          }
        />
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Ajouter le client
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateClient;
