// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdArticle,
  MdAttachMoney,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson,
  MdSupervisorAccount,
  MdInsertDriveFile,
  MdAccountBalanceWallet,
} from "react-icons/md";
import Article from "views/admin/article";
import CreateArticle from "views/admin/article/create";
import UpdateArticle from "views/admin/article/update";
import Staff from "views/admin/staff";
import AddToStaff from "views/admin/staff/create";
import Subscription from "views/admin/subscription";
import CancelSubscription from "views/admin/subscription/cancel";
import SubscriptionHistory from "views/admin/subscription/history";
import SuccessSubscription from "views/admin/subscription/success";
import ForgetPassword from "views/auth/ForgetPassword";
import SetPassword from "views/auth/SetPassword";
import Devis from "views/admin/devis";
import CreateDevis from "views/admin/devis/create";
import UpdateDevis from "views/admin/devis/update";
import Invoice from "views/admin/invoice";
import CreateInvoice from "views/admin/invoice/create";
import UpdateInvoice from "views/admin/invoice/update";
import Clients from "views/admin/client";
import CreateClient from "views/admin/client/create";
import UpdateClient from "views/admin/client/update";
const routes = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Workspace Staff",
    layout: "/admin",
    path: "staff",
    icon: <MdSupervisorAccount className="h-6 w-6" />,
    component: <Staff />,
    show: true,
    admin: true,
  },
  {
    name: "Clients",
    layout: "/admin",
    path: "client",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Clients />,
    show: true,
  },
  {
    name: "Create Client",
    layout: "/admin",
    path: "client/create",
    icon: <MdPerson className="h-6 w-6" />,
    component: <CreateClient />,
  },
  {
    name: "Update Client",
    layout: "/admin",
    path: "client/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UpdateClient />,
  },
  {
    name: "Article",
    layout: "/admin",
    path: "article",
    icon: <MdArticle className="h-6 w-6" />,
    component: <Article />,
    show: true,
  },
  {
    name: "Create Article",
    layout: "/admin",
    path: "article/create",
    icon: <MdArticle className="h-6 w-6" />,
    component: <CreateArticle />,
  },
  {
    name: "Update Article",
    layout: "/admin",
    path: "article/:id",
    icon: <MdArticle className="h-6 w-6" />,
    component: <UpdateArticle />,
  },
  {
    name: "Devis",
    layout: "/admin",
    path: "devis",
    icon: <MdInsertDriveFile className="h-6 w-6" />,
    component: <Devis />,
    show: true,
  },
  {
    name: "Create Devis",
    layout: "/admin",
    path: "devis/create",
    icon: <MdInsertDriveFile className="h-6 w-6" />,
    component: <CreateDevis />,
  },
  {
    name: "Update Devis",
    layout: "/admin",
    path: "devis/:id",
    icon: <MdInsertDriveFile className="h-6 w-6" />,
    component: <UpdateDevis />,
  },
  {
    name: "Facture",
    layout: "/admin",
    path: "invoice",
    icon: <MdAccountBalanceWallet className="h-6 w-6" />,
    component: <Invoice />,
    show: true,
  },
  {
    name: "Create Facture",
    layout: "/admin",
    path: "invoice/create",
    icon: <MdAccountBalanceWallet className="h-6 w-6" />,
    component: <CreateInvoice />,
  },
  {
    name: "Update Facture",
    layout: "/admin",
    path: "invoice/:id",
    icon: <MdAccountBalanceWallet className="h-6 w-6" />,
    component: <UpdateInvoice />,
  },
  {
    name: "Abonnements",
    layout: "/admin",
    path: "subscriptions",
    icon: <MdAttachMoney className="h-6 w-6" />,
    component: <SubscriptionHistory />,
    show: true,
    admin: true,
  },
  {
    name: "Ajouter un membre au staff",
    layout: "/admin",
    path: "staff/create",
    icon: <MdHome className="h-6 w-6" />,
    component: <AddToStaff />,
    admin: true,
  },
  {
    name: "Définir un mot de passe",
    layout: "/auth",
    path: "set-password",
    icon: <MdHome className="h-6 w-6" />,
    component: <SetPassword />,
  },
  {
    name: "Mots de passe oublié?",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPassword />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription",
    icon: <MdLock className="h-6 w-6" />,
    component: <Subscription />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription/success",
    icon: <MdLock className="h-6 w-6" />,
    component: <SuccessSubscription />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription/cancel",
    icon: <MdLock className="h-6 w-6" />,
    component: <CancelSubscription />,
  },
];
export default routes;
