import React, { useState, useEffect, useRef, useCallback } from "react";

export interface Option {
  id: string;
  name: string;
}

interface AutocompleteProps {
  options: Option[];
  label: string;
  onSelect: (selectedOption: Option) => void;
  value: string[];
  searchValue: string;
  setSearchValue: (value: string) => void;
  isMultiple?: boolean;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  onSelect,
  label,
  value,
  searchValue,
  setSearchValue,
  isMultiple = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender && filteredOptions.length < 1 && options.length > 0) {
      setFilteredOptions(options);
      setFirstRender(true);
    }
  }, [filteredOptions.length, firstRender, options]);

  // Get selected options based on value array
  const selectedOptions = options.filter((option) => value.includes(option.id));

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setSearchValue("");
    setIsDropdownOpen(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
    // Show selected values as comma-separated string when not focused
    setSearchValue(selectedOptions.map((opt) => opt.name).join(", "));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    setFilteredOptions(
      options.filter((option) =>
        option.name.toLowerCase().includes(value.toLowerCase())
      )
    );
    setIsDropdownOpen(true);
  };

  const handleOptionClick = useCallback(
    (option: Option) => {
      if (isMultiple) {
        onSelect(option);
        let newValues = selectedOptions.map((opt) => opt.name);
        if (value.includes(option.id)) {
          newValues = selectedOptions
            .filter((opt) => opt.id !== option.id)
            .map((opt) => opt.name);
        } else {
          newValues.push(option.name);
        }
        console.log(newValues.join(", "));
        setSearchValue(newValues.join(", "));
      } else {
        // For single select, close dropdown after selection
        onSelect(option);
        setIsDropdownOpen(false);
        setSearchValue(option.name);
      }
    },
    [isMultiple, onSelect, selectedOptions, setSearchValue, value]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div id="hs-combobox-basic-usage" className="relative" data-hs-combo-box="">
      <label className={"text-sm font-medium text-navy-700 dark:text-white"}>
        {label}
      </label>
      <div className="relative">
        <input
          className="dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 mt-2 block w-full rounded-lg border border-gray-200 py-3 pe-9 ps-4 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50"
          type="text"
          role="combobox"
          aria-expanded="false"
          value={searchValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          placeholder={isInputFocused ? "Rechercher..." : ""}
          data-hs-combo-box-input=""
        />
        <div
          className="absolute end-3 top-1/2 -translate-y-1/2"
          aria-expanded="false"
          data-hs-combo-box-toggle=""
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <svg
            className="dark:text-neutral-500 size-3.5 shrink-0 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m7 15 5 5 5-5"></path>
            <path d="m7 9 5-5 5 5"></path>
          </svg>
        </div>
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700 absolute z-50 max-h-72 w-full overflow-hidden overflow-y-auto rounded-lg border border-gray-200 bg-white p-1 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar]:w-2"
          data-hs-combo-box-output=""
        >
          {filteredOptions.map((option, index) => (
            <div
              key={option.id}
              className={`dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800 w-full cursor-pointer rounded-lg px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none ${
                value.includes(option.id)
                  ? "bg-neutral-800 dark:bg-neutral-700"
                  : ""
              }`}
              tabIndex={index}
              onClick={() => handleOptionClick(option)}
            >
              <div className="flex w-full items-center justify-between">
                <span>{option.name}</span>
                {value.includes(option.id) && (
                  <span>
                    <svg
                      className="size-3.5 shrink-0 text-blue-600 dark:text-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M20 6 9 17l-5-5"></path>
                    </svg>
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
