import { useGetInvoices } from "modules/invoice/api";
import { Invoice } from "modules/invoice/types/invoice.interface";
import { useState } from "react";
import InvoiceTable, {
  RowObjInvoice,
} from "../default/components/invoiceTable";
import { Spinner } from "components/Spinner";

const AllInvoices = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage, fetchPreviousPage } = useGetInvoices(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  const flatData = data?.pages[currentPage - 1]?.data ?? [];
  console.log(flatData);

  const handleFormatData = (data: Invoice[]): RowObjInvoice[] => {
    return data.map((item) => ({
      _id: item._id,
      workspaceId: item.workspaceId,
      clientId: item.clientId,
      createdAt: item.createdAt,
      total: item.total,
      dueDate: item.dueDate,
      updatedAt: item.updatedAt,
      status: item.status,
      archived: item.archived,
      lineItems: item.lineItems,
    }));
  };

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 1) {
      fetchPreviousPage();
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {flatData && flatData.length > 0 ? (
          <InvoiceTable
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages[0].count}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        ) : (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllInvoices;
