import { Pagination, SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Devis, CreateDevisPayload } from "modules/devis/types/devis.interface";

export interface DevisBackend {
  getAllDevis(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Devis>>;
  getDevis(id: string): Promise<Devis>;
  createDevis(
    workspaceId: string,
    payload: CreateDevisPayload
  ): Promise<Devis>;
  updateDevis(id: string, payload: CreateDevisPayload): Promise<Devis>;
  archiveDevis(id: string): Promise<SuccessResponse>;
}

let devisBackendInstance: DevisBackend | undefined;

export async function getDevisBackend(): Promise<DevisBackend> {
  if (devisBackendInstance === undefined) {
    const mod = await import(`./${environment.ARTICLE_BACKEND}`);
    devisBackendInstance = new mod.default() as DevisBackend;
  }
  return devisBackendInstance;
}
