import { Pagination, SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  Article,
  Client,
  CreateArticlePayload,
} from "modules/article/types/article.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { ARTICLE_ROUTES } from "./article.api.enum";
import { getArticleBackend } from "./backends";

export const useGetArticles = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Article>> => {
  const setSnack = useSnack();

  const getArticle = async ({
    pageParam = 1,
  }): Promise<Pagination<Article>> => {
    const articleBackend = await getArticleBackend();
    console.log("pageParam", pageParam);
    return await articleBackend.getArticles(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    ARTICLE_ROUTES.GET_ALL + searchName,
    getArticle,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetClients = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Client>> => {
  const setSnack = useSnack();

  const getArticle = async ({
    pageParam = 1,
  }): Promise<Pagination<Client>> => {
    const articleBackend = await getArticleBackend();
    return await articleBackend.getClients(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    ARTICLE_ROUTES.GET_ALL + searchName,
    getArticle,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetArticle = (
  id: string | undefined
): UseQueryResult<Article> => {
  const setSnack = useSnack();

  const getArticle = async (): Promise<Article> => {
    const ArticleBackend = await getArticleBackend();
    return await ArticleBackend.getArticle(id);
  };

  return useQueryWithLoading(ARTICLE_ROUTES.GET + id, () => getArticle(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateArticle = (): UseMutationResult<
  Article,
  Error,
  CreateArticlePayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.user.user);

  const createArticle = async (
    payload: CreateArticlePayload
  ): Promise<Article> => {
    const ArticleBackend = await getArticleBackend();
    return await ArticleBackend.createArticle(user.workspaceId, payload);
  };

  return useMutationWithLoading(
    (payload: CreateArticlePayload) => createArticle(payload),
    {
      onSuccess() {
        setSnack({
          title: "Article créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(ARTICLE_ROUTES.GET_ALL);

        navigate("/admin/article");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateArticle = (
  id: string | undefined
): UseMutationResult<Article, Error, CreateArticlePayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateArticle = async (
    payload: CreateArticlePayload
  ): Promise<Article> => {
    const ArticleBackend = await getArticleBackend();
    return await ArticleBackend.updateArticle(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateArticlePayload) => updateArticle(payload),
    {
      onSuccess() {
        setSnack({
          title: "Article modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(ARTICLE_ROUTES.GET_ALL);

        navigate("/admin/article");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useArchiveArticle = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const archiveArticle = async (id: string): Promise<SuccessResponse> => {
    const ArticleBackend = await getArticleBackend();
    return await ArticleBackend.archiveArticle(id);
  };

  return useMutationWithLoading((id: string) => archiveArticle(id), {
    onSuccess() {
      setSnack({
        title: "Article supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(ARTICLE_ROUTES.GET_ALL);

      navigate("/admin/article");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
  
};
