import { Pagination, SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Article, Client, CreateArticlePayload } from "modules/article/types/article.interface";

export interface ArticleBackend {
  getArticles(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Article>>;
  getClients(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Client>>;
  getArticle(id: string): Promise<Article>;
  createArticle(
    workspaceId: string,
    payload: CreateArticlePayload
  ): Promise<Article>;
  updateArticle(id: string, payload: CreateArticlePayload): Promise<Article>;
  archiveArticle(id: string): Promise<SuccessResponse>;
}

let articleBackendInstance: ArticleBackend | undefined;

export async function getArticleBackend(): Promise<ArticleBackend> {
  if (articleBackendInstance === undefined) {
    const mod = await import(`./${environment.ARTICLE_BACKEND}`);
    articleBackendInstance = new mod.default() as ArticleBackend;
  }
  return articleBackendInstance;
}
