import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import { InputField } from "components/fields/InputField";
import { useGetInvoice, useUpdateInvoice } from "modules/invoice/api";
import {
  CreateInvoicePayload,
  ReductionType,
  InvoiceLineItem,
  INVOICE_STATUS,
} from "modules/invoice/types/invoice.interface";
import { FormEvent, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { useGetArticles } from "modules/article/api";
import {
  SelectDevis,
  SelectIntervenants,
  SelectClient,
  SelectStatus,
  SelectArticle,
  SelectReductionType,
} from "modules/invoice/components";
import { useGetAllDevis } from "modules/devis/api";

const UpdateInvoice = () => {
  const { id } = useParams<{ id: string }>();
  const { data: invoice } = useGetInvoice(id);
  const { mutate } = useUpdateInvoice(id);

  const { data: devisData } = useGetAllDevis(undefined);
  const devisList = devisData?.pages.flatMap((page) => page.data) || [];

  const { data: articlesData } = useGetArticles(undefined);

  const { formValues, setFormValues } = useCustomForm<CreateInvoicePayload>({
    clientId: "",
    dueDate: null,
    interveners: [],
    status: INVOICE_STATUS.DRAFT,
    lineItems: [
      {
        articleId: "",
        priceHT: 0,
        quantity: 0,
        vatAmount: 0,
        reductionAmount: 0,
        reductionType: ReductionType.PERCENTAGE,
        total: 0,
        description: "",
      },
    ],
    total: 0,
  });

  console.log(formValues.lineItems);
  const isSubmitDisabled =
    !formValues.clientId ||
    formValues.lineItems.some(
      (item) =>
        item.quantity === 0 ||
        item.total === 0 ||
        (item.description === "" && item.articleId === "")
    );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSubmitDisabled) return;
    mutate(formValues);
  };

  useEffect(() => {
    if (invoice && articlesData) {
      const allArticles = articlesData.pages.flatMap((page) => page.data);

      const updatedLineItems = invoice.lineItems.map((item) => {
        const matchedArticle = allArticles.find(
          (a) => a._id === item.articleId
        );

        return {
          ...item,
          type: matchedArticle?.type,
          priceHT: matchedArticle?.priceHT || item.priceHT,
          quantity: Number(item.quantity) || 0,
          vatAmount: Number(item.vatAmount) || 0,
          reductionAmount: Number(item.reductionAmount) || 0,
          reductionType: item.reductionType || ReductionType.PERCENTAGE,
          total: Number(item.total) || 0,
        };
      });

      setFormValues({
        ...invoice,
        dueDate: new Date(invoice.dueDate),
        interveners: invoice.interveners || [],
        lineItems: updatedLineItems,
      });
    }
  }, [invoice, articlesData, setFormValues]);

  const handleAddLineItem = () => {
    setFormValues({
      ...formValues,
      lineItems: [
        ...formValues.lineItems,
        {
          articleId: "",
          quantity: 0,
          reductionType: ReductionType.PERCENTAGE,
          reductionAmount: 0,
          vatAmount: 20,
          priceHT: 0,
          description: "",
          total: 0,
        },
      ],
    });
  };

  const handleRemoveLineItem = (index: number) => {
    const updatedLineItems = formValues.lineItems.filter(
      (_, idx) => idx !== index
    );
    const newTotalInvoice = updatedLineItems.reduce(
      (acc, item) => acc + item.total,
      0
    );
    setFormValues({
      ...formValues,
      lineItems: updatedLineItems,
      total: Number(newTotalInvoice.toFixed(2)),
    });
  };

  const handleLineItemChange = (
    index: number,
    field: keyof InvoiceLineItem,
    value: any
  ) => {
    const updatedLineItems = formValues.lineItems.map((item, idx) => {
      if (idx === index) {
        const newItem = { ...item, [field]: value };

        if (field === "articleId") {
          const allArticles =
            articlesData?.pages.flatMap((page) => page.data) || [];
          const selectedArticle = allArticles.find((a) => a._id === value);
          if (selectedArticle) {
            newItem.priceHT = selectedArticle.priceHT;
          }
        }

        const priceHT = Number(newItem.priceHT) || 0;
        const quantity = Number(newItem.quantity) || 0;
        const vatPercent = Number(newItem.vatAmount) || 0; // TVA %
        const reductionAmount = Number(newItem.reductionAmount) || 0;
        const reductionType = newItem.reductionType;

        // Calcul des nouvelles valeurs
        const ht = priceHT * quantity;
        const vatAmount = ht * (vatPercent / 100);
        const baseAmount = ht + vatAmount;

        let reduction =
          reductionType === ReductionType.PERCENTAGE
            ? baseAmount * (reductionAmount / 100)
            : reductionAmount;

        const total = Math.max(0, baseAmount - reduction);

        // Mise à jour des valeurs calculées
        newItem.vatAmount = vatPercent; // Conserver le pourcentage
        newItem.total = Number(total.toFixed(2));

        return newItem;
      }
      return item;
    });

    // Calcul du nouveau total global
    const newTotalInvoice = updatedLineItems.reduce(
      (acc, item) => acc + item.total,
      0
    );

    setFormValues((prev) => ({
      ...prev,
      lineItems: updatedLineItems,
      total: Number(newTotalInvoice.toFixed(2)),
    }));
  };

  const { totalHT, totalRemise, totalVAT } = formValues.lineItems.reduce(
    (acc, item) => {
      const priceHT = item.priceHT !== undefined ? item.priceHT : item.total / (1 + item.vatAmount / 100);
      const ht = priceHT * item.quantity;
      const vat = ht * (item.vatAmount / 100);
      const baseAmount = ht + vat;
      const reduction =
        item.reductionType === ReductionType.PERCENTAGE
          ? baseAmount * ((item.reductionAmount ?? 0) / 100)
          : item.reductionAmount ?? 0;

      acc.totalHT += ht;
      acc.totalRemise += reduction;
      acc.totalVAT += vat;
      return acc;
    },
    { totalHT: 0, totalRemise: 0, totalVAT: 0 }
  );

  const totalHTFinal = totalHT - totalRemise;
  const totalTTC = totalHTFinal + totalVAT;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Formulaire Facture">
        <div className="mb-5">
          <SelectDevis
            formValues={formValues}
            setFormValues={setFormValues}
            devisList={devisList}
          />
        </div>
        <div className="grid grid-cols-3 gap-5">
          <SelectClient formValues={formValues} setFormValues={setFormValues} />
          <InputField
            variant="auth"
            label="Échéance*"
            id="dueDate"
            type="date"
            extra="mb-3"
            value={
              formValues.dueDate
                ? formValues.dueDate.toISOString().split("T")[0]
                : ""
            }
            placeholder="Date d'échéance"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                dueDate: e.target.value ? new Date(e.target.value) : null,
              })
            }
          />
          <SelectIntervenants
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <SelectStatus formValues={formValues} setFormValues={setFormValues} />
        </div>

        <div className="mt-6">
          <h3 className="mb-4 text-xl font-semibold">Articles</h3>
          {formValues.lineItems.map((item, index) => (
            <div
              key={index}
              className="relative mb-6 rounded-[15px] border p-6 shadow-lg"
            >
              <button
                type="button"
                onClick={() => handleRemoveLineItem(index)}
                className="absolute right-2 top-0 text-3xl text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
              <div className="grid grid-cols-7 gap-4">
                <div className="col-span-1">
                  <SelectArticle
                    value={item.articleId}
                    onChange={(articleId) =>
                      handleLineItemChange(index, "articleId", articleId)
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Prix HT"
                    id={`priceHT-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0.00"
                    value={item.priceHT}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "priceHT",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Quantité*"
                    id={`quantity-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0"
                    value={item.quantity}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "quantity",
                        parseInt(e.target.value, 10) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="TVA %"
                    id={`vatAmount-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="20.00"
                    value={item.vatAmount || ""}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "vatAmount",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Réduction"
                    id={`reductionAmount-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0.00"
                    value={item.reductionAmount || ""}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "reductionAmount",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <SelectReductionType
                    value={item.reductionType}
                    onChange={(reductionType) =>
                      handleLineItemChange(
                        index,
                        "reductionType",
                        reductionType
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Total TTC"
                    id={`total-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0.00"
                    value={item.total}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-4">
                <InputField
                  variant="auth"
                  label="Description"
                  id={`description-${index}`}
                  type="textarea"
                  extra="mb-3"
                  placeholder="Description de l'item"
                  value={item.description}
                  onChange={(e) =>
                    handleLineItemChange(index, "description", e.target.value)
                  }
                />
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddLineItem}
              className="cursor-pointer rounded-full bg-brand-500 p-0.5 text-white"
              type="button"
            >
              <svg
                className="h-8 w-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="mt-6 flex justify-end text-left">
          <CardWithTitle title="Totaux">
            <div className="grid w-80 grid-cols-2 gap-3">
              <div className="text-sm font-medium text-gray-600">Total HT</div>
              <div className="text-right text-sm">
                {totalHT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">
                Remise générale
              </div>
              <div className="text-right text-sm">
                {totalRemise.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">
                Total HT Final
              </div>
              <div className="text-right text-sm">
                {totalHTFinal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">TVA</div>
              <div className="text-right text-sm">
                {totalVAT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">Total TTC</div>
              <div className="text-right text-sm font-semibold">
                {totalTTC.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
            </div>
          </CardWithTitle>
        </div>
      </CardWithTitle>

      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            variant="primary"
          >
            Mettre à jour la facture
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpdateInvoice;
