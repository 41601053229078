import { Pagination, SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  Invoice,
  CreateInvoicePayload,
} from "modules/invoice/types/invoice.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { INVOICE_ROUTES } from "./invoice.api.enum";
import { getInvoiceBackend } from "./backends";

export const useGetInvoices = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Invoice>> => {
  const setSnack = useSnack();

  const getAllInvoices = async ({
    pageParam = 1,
  }): Promise<Pagination<Invoice>> => {
    const invoiceBackend = await getInvoiceBackend();
    console.log("pageParam", pageParam);
    return await invoiceBackend.getInvoices(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    INVOICE_ROUTES.GET_ALL + searchName,
    getAllInvoices,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetInvoice = (
  id: string | undefined
): UseQueryResult<Invoice> => {
  const setSnack = useSnack();

  const getInvoice = async (): Promise<Invoice> => {
    const invoiceBackend = await getInvoiceBackend();
    return await invoiceBackend.getInvoice(id);
  };

  return useQueryWithLoading(INVOICE_ROUTES.GET + id, () => getInvoice(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateInvoice = (): UseMutationResult<
  Invoice,
  Error,
  CreateInvoicePayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.user.user);

  const createInvoice = async (
    payload: CreateInvoicePayload
  ): Promise<Invoice> => {
    const invoiceBackend = await getInvoiceBackend();
    return await invoiceBackend.createInvoice(user.workspaceId, payload);
  };

  return useMutationWithLoading(
    (payload: CreateInvoicePayload) => createInvoice(payload),
    {
      onSuccess() {
        setSnack({
          title: "Facture créée avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(INVOICE_ROUTES.GET_ALL);

        navigate("/admin/invoice");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateInvoice = (
  id: string | undefined
): UseMutationResult<Invoice, Error, CreateInvoicePayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateInvoice = async (
    payload: CreateInvoicePayload
  ): Promise<Invoice> => {
    const invoiceBackend = await getInvoiceBackend();
    return await invoiceBackend.updateInvoice(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateInvoicePayload) => updateInvoice(payload),
    {
      onSuccess: (data) => {
        setSnack({
          title: "Facture modifiée avec succès!",
          severityType: "success",
        });
        
        queryClient.invalidateQueries([INVOICE_ROUTES.GET, id]);
        queryClient.setQueryData([INVOICE_ROUTES.GET, id], data);

        navigate("/admin/invoice");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useArchiveInvoice = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const archiveInvoice = async (id: string): Promise<SuccessResponse> => {
    const invoiceBackend = await getInvoiceBackend();
    return await invoiceBackend.archiveInvoice(id);
  };

  return useMutationWithLoading((id: string) => archiveInvoice(id), {
    onSuccess() {
      setSnack({
        title: "Facture supprimée avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(INVOICE_ROUTES.GET_ALL);

      navigate("/admin/invoice");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
