import { useGetClients } from "modules/client/api";
import { Client } from "modules/client/types/client.interface";
import { useState } from "react";
import ClientTable, { RowObjClient } from "../default/components/clientTable";
import { Spinner } from "components/Spinner";

const Clients = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetClients(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  const flatData = data?.pages[currentPage - 1]?.data ?? [];

  const handleFormatData = (data: Client[]): RowObjClient[] => {
    return data.map((item) => ({
      id: item._id,
      name: item.name,
      email: item.email ? item.email : [{ key: "-", value: "-" }],
      phoneNumber: item.phoneNumber ? item.phoneNumber : "-",
      address: item.address ? item.address : "-",
    }));
  };

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {flatData && flatData.length > 0 ? (
          <ClientTable
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages[0].count}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        ) : (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
