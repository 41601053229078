import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import { InputField } from "components/fields/InputField";
import { useCreateDevis } from "modules/devis/api";
import {
  CreateDevisPayload,
  ReductionType,
  DevisLineItem,
  DEVIS_STATUS,
} from "modules/devis/types/devis.interface";
import { FormEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useGetArticles } from "modules/article/api";
import { SelectIntervenants } from "modules/devis/components";
import { SelectClient } from "modules/devis/components";
import { SelectStatus } from "modules/devis/components";
import { SelectArticle } from "modules/devis/components";
import { SelectReductionType } from "modules/devis/components";

const CreateDevis = () => {
  const { mutate } = useCreateDevis();
  const { data: articlesData } = useGetArticles(undefined);
  const { formValues, setFormValues } = useCustomForm<CreateDevisPayload>({
    clientId: "",
    dueDate: new Date(),
    interveners: [],
    lineItems: [
      {
        articleId: "",
        priceHT: 0,
        quantity: 0,
        vatAmount: 0,
        reductionAmount: 0,
        reductionType: ReductionType.PERCENTAGE,
        total: 0,
        description: "",
      },
    ],
    total: 0,
    status: DEVIS_STATUS.DRAFT,
  });

  const isSubmitDisabled =
    !formValues.clientId ||
    formValues.lineItems.some(
      (item) => !item.articleId || item.quantity <= 0 || item.total <= 0
    );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSubmitDisabled) return;
    mutate(formValues);
  };

  const handleAddLineItem = () => {
    setFormValues({
      ...formValues,
      lineItems: [
        ...formValues.lineItems,
        {
          articleId: "",
          quantity: 0,
          reductionType: ReductionType.PERCENTAGE,
          reductionAmount: 0,
          vatAmount: 0,
          priceHT: 0,
          description: "",
          total: 0,
        },
      ],
    });
  };

  const handleRemoveLineItem = (index: number) => {
    const updatedLineItems = formValues.lineItems.filter(
      (_, idx) => idx !== index
    );
    const newTotalDevis = updatedLineItems.reduce(
      (acc, item) => acc + item.total,
      0
    );
    setFormValues({
      ...formValues,
      lineItems: updatedLineItems,
      total: Number(newTotalDevis.toFixed(2)),
    });
  };

  const handleLineItemChange = (
    index: number,
    field: keyof DevisLineItem,
    value: any
  ) => {
    const updatedLineItems = formValues.lineItems.map((item, idx) => {
      if (idx === index) {
        const newItem = { ...item, [field]: value };

        if (field === "articleId") {
          const allArticles =
            articlesData?.pages.flatMap((page) => page.data) || [];
          const selectedArticle = allArticles.find((a) => a._id === value);
          if (selectedArticle) {
            newItem.priceHT = selectedArticle.priceHT;
          }
        }

        const priceHT = Number(newItem.priceHT) || 0;
        const quantity = Number(newItem.quantity) || 0;
        const vatPercent = Number(newItem.vatAmount) || 0; // Pourcentage TVA
        const reductionValue = Number(newItem.reductionAmount) || 0;
        const reductionType = newItem.reductionType;

        // Calcul des nouvelles valeurs
        const ht = priceHT * quantity;
        const vatAmount = ht * (vatPercent / 100);
        const baseAmount = ht + vatAmount;

        let reduction =
          reductionType === ReductionType.PERCENTAGE
            ? baseAmount * (reductionValue / 100)
            : reductionValue;

        const total = Math.max(0, baseAmount - reduction);

        // Mise à jour des valeurs calculées
        newItem.vatAmount = vatPercent; // Conserver le pourcentage
        newItem.total = Number(total.toFixed(2));

        return newItem;
      }
      return item;
    });

    // Calcul du nouveau total global
    const newTotalDevis = updatedLineItems.reduce(
      (acc, item) => acc + item.total,
      0
    );

    setFormValues((prev) => ({
      ...prev,
      lineItems: updatedLineItems,
      total: Number(newTotalDevis.toFixed(2)),
    }));
  };

  const { totalHT, totalRemise, totalVAT } = formValues.lineItems.reduce(
    (acc, item) => {
      const priceHT =
        item.priceHT !== undefined
          ? item.priceHT
          : item.total / (1 + item.vatAmount / 100);
      const ht = priceHT * item.quantity;
      const vat = ht * (item.vatAmount / 100);
      const baseAmount = ht + vat;
      const reduction =
        item.reductionType === ReductionType.PERCENTAGE
          ? baseAmount * (item.reductionAmount / 100)
          : item.reductionAmount;

      acc.totalHT += ht;
      acc.totalRemise += reduction;
      acc.totalVAT += vat;
      return acc;
    },
    { totalHT: 0, totalRemise: 0, totalVAT: 0 }
  );

  const totalHTFinal = totalHT - totalRemise;
  const totalTTC = totalHTFinal + totalVAT;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Nouveau Devis">
        <div className="grid grid-cols-3 gap-5">
          <SelectClient formValues={formValues} setFormValues={setFormValues} />
          <InputField
            variant="auth"
            label="Échéance*"
            id="dueDate"
            type="date"
            extra="mb-3"
            value={formValues.dueDate.toISOString().split("T")[0]}
            placeholder="Date d'échéance"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                dueDate: new Date(e.target.value),
              })
            }
          />
          <SelectIntervenants
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <SelectStatus formValues={formValues} setFormValues={setFormValues} />
        </div>

        <div className="mt-6">
          <h3 className="mb-4 text-xl font-semibold">Articles</h3>
          {formValues.lineItems.map((item, index) => (
            <div
              key={index}
              className="relative mb-6 rounded-[15px] border p-6 shadow-lg"
            >
              <button
                type="button"
                onClick={() => handleRemoveLineItem(index)}
                className="absolute right-2 top-0 text-3xl text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
              <div className="grid grid-cols-7 gap-4">
                <div className="col-span-1">
                  <SelectArticle
                    value={item.articleId}
                    onChange={(articleId) =>
                      handleLineItemChange(index, "articleId", articleId)
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Prix HT"
                    id={`priceHT-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0.00"
                    value={item.priceHT}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "priceHT",
                        parseInt(e.target.value, 10) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Quantité*"
                    id={`quantity-${index}`}
                    type="number"
                    extra="mb-3"
                    placeholder="0.00"
                    value={item.quantity}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "quantity",
                        parseInt(e.target.value, 10) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="TVA %"
                    id={`vatAmount-${index}`}
                    placeholder="0.00"
                    type="number"
                    extra="mb-3"
                    value={item.vatAmount || ""}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "vatAmount",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Réduction"
                    id={`reductionAmount-${index}`}
                    placeholder="0.00"
                    type="number"
                    extra="mb-3"
                    value={item.reductionAmount || ""}
                    onChange={(e) =>
                      handleLineItemChange(
                        index,
                        "reductionAmount",
                        parseFloat(e.target.value) || 0
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <SelectReductionType
                    value={item.reductionType}
                    onChange={(reductionType) =>
                      handleLineItemChange(
                        index,
                        "reductionType",
                        reductionType
                      )
                    }
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    variant="auth"
                    label="Total TTC"
                    id={`total-${index}`}
                    placeholder="0.00"
                    type="number"
                    extra="mb-3"
                    value={item.total}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-4">
                <InputField
                  variant="auth"
                  label="Description"
                  id={`description-${index}`}
                  type="textarea"
                  extra="mb-3"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) =>
                    handleLineItemChange(index, "description", e.target.value)
                  }
                />
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <button
              onClick={handleAddLineItem}
              className="cursor-pointer rounded-full bg-brand-500 p-0.5 text-white"
              type="button"
            >
              <svg
                className="h-8 w-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="mt-6 flex justify-end text-left">
          <CardWithTitle title="Totaux">
            <div className="grid w-80 grid-cols-2 gap-3">
              <div className="text-sm font-medium text-gray-600">Total HT</div>
              <div className="text-right text-sm">
                {totalHT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">
                Remise générale
              </div>
              <div className="text-right text-sm">
                {totalRemise.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">
                Total HT Final
              </div>
              <div className="text-right text-sm">
                {totalHTFinal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                €
              </div>
              <div className="text-sm font-medium text-gray-600">TVA</div>
              <div className="text-right text-sm">
                {totalVAT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
              <div className="text-sm font-medium text-gray-600">Total TTC</div>
              <div className="text-right text-sm font-semibold">
                {totalTTC.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
              </div>
            </div>
          </CardWithTitle>
        </div>
      </CardWithTitle>

      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            variant="primary"
          >
            Créer le devis
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateDevis;
