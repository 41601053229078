import { environment } from "common/environment";
import { Button } from "components/button";
import { InputField } from "components/fields/InputField";
import {
  useResendSetPassword,
  useSetPassword,
  useVerifyIfCodeIsValid,
} from "modules/auth/api";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function SetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const email = new URLSearchParams(location.search).get("email");
  const [inputCode, setInputCode] = useState("");
  const { mutate: verifyCode, data } = useVerifyIfCodeIsValid();
  const { mutate: setPasswordMutation } = useSetPassword();
  const { mutate: resendCode, data: isFetched } = useResendSetPassword();

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (data?.success) {
        verifyCode(inputCode);
      }

      if (
        data?.success &&
        password.length >= 8 &&
        password === confirmPassword
      ) {
        setPasswordMutation({ password, code: code || inputCode });
      }
    },
    [
      code,
      confirmPassword,
      data?.success,
      inputCode,
      password,
      setPasswordMutation,
      verifyCode,
    ]
  );

  useEffect(() => {
    if (code && !data) {
      verifyCode(code);
    }
  }, [code, data, verifyCode]);

  const handleCorrectInput = useCallback(() => {
    if (!data?.success) {
      return (
        <>
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Vérifier le code
          </h4>
          <p className="mb-6 ml-1 text-base text-gray-600">
            Entrer le code que vous avez reçu par email.
          </p>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Code de vérification*"
            placeholder="123456"
            id="code-de-verification"
            type="text"
            value={inputCode}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setInputCode(e.target.value);
              }
            }}
          />
          <p className="-mt-1 mb-4 ml-1 text-sm text-gray-600">
            Vous n'avez pas reçu de code?{" "}
            <span
              className={
                !isFetched
                  ? "cursor-pointer text-blue-500"
                  : "cursor-not-allowed text-gray-400"
              }
              onClick={() => (isFetched ? false : resendCode(email))}
            >
              Renvoyer le code
            </span>
          </p>
          <Button
            onClick={() => verifyCode(inputCode)}
            disabled={inputCode.length !== 6}
          >
            Verifier le code
          </Button>
        </>
      );
    }

    return (
      <>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Définir un mot de passe
        </h4>
        <p className="mb-6 ml-1 text-base text-gray-600">
          Créez un mot de passe pour votre compte.
        </p>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Confirmer mots de passe*"
          placeholder="Min. 8 characters"
          id="confirm-password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPassword.length > 0 && password !== confirmPassword && (
          <p className="-mt-1.5 mb-2 text-xs text-red-500">
            Les mots de passe ne correspondent pas
          </p>
        )}
        <Button
          onClick={handleSubmit}
          disabled={password.length < 8 || password !== confirmPassword}
        >
          Enregistrer
        </Button>
      </>
    );
  }, [
    data?.success,
    password,
    confirmPassword,
    handleSubmit,
    inputCode,
    isFetched,
    resendCode,
    email,
    verifyCode,
  ]);

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {handleCorrectInput()}
      </div>
      <footer className="absolute bottom-0 left-0 w-full p-4 text-center text-gray-500 dark:text-gray-400">
        <a href={`mailto:${environment.SUPPORT_EMAIL}`}>Contacter le support</a>
      </footer>
    </form>
  );
}
