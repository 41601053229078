import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import { InputField } from "components/fields/InputField";
import {
  useGetClient,
  useUpdateClient,
  useArchiveClient,
} from "modules/client/api";
import { CreateClientPayload } from "modules/client/types/client.interface";
import { FormEvent, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";

const UpdateClient = () => {
  const id = useParams<{ id: string | undefined }>().id;
  const { data: client } = useGetClient(id);
  const { mutate } = useUpdateClient(id);
  const { mutate: archiveClient } = useArchiveClient();
  const { formValues, setFormValues } = useCustomForm<CreateClientPayload>({
    name: "",
    email: [],
    phoneNumber: "",
    address: "",
    postalCode: "",
    city: "",
    siret: "",
    codeNaf: "",
    tvaCode: "",
  });

  const isSubmitDisabled =
    !formValues.name ||
    formValues.name === "" ||
    !formValues.address ||
    formValues.address === "" ||
    !formValues.postalCode ||
    formValues.postalCode === "" ||
    !formValues.city ||
    formValues.city === "" ||
    !formValues.siret ||
    formValues.siret === "" ||
    !formValues.tvaCode ||
    formValues.tvaCode === "";

  const handleSubmit = (e: FormEvent) => {
    if (isSubmitDisabled) return;

    e.preventDefault();

    mutate({
      ...formValues,
    });
  };

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (client && firstRender) {
      setFormValues({
        name: client.name,
        //email: client.email,
        email: [],
        phoneNumber: client.phoneNumber,
        address: client.address,
        postalCode: client.postalCode,
        city: client.city,
        siret: client.siret,
        codeNaf: client.codeNaf,
        tvaCode: client.tvaCode,
      });
      setFirstRender(false);
    }
  }, [firstRender, formValues, client, setFormValues]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Information du client">
        <InputField
          variant="auth"
          extra="mb-3"
          label="Nom du client*"
          placeholder="Nom du client"
          id="name"
          type="text"
          value={formValues.name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              name: e.target.value,
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="email@example.com"
          id="email"
          type="email"
          value={formValues.email[0].value}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              email: [{ key: "email", value: e.target.value }],
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Numéro de téléphone"
          placeholder="0123456789"
          id="phoneNumber"
          type="text"
          value={formValues.phoneNumber}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              phoneNumber: e.target.value,
            })
          }
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Adresse*"
          placeholder="123 Rue Exemple"
          id="address"
          type="text"
          value={formValues.address}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              address: e.target.value,
            })
          }
        />
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Code postal*"
            placeholder="75001"
            id="postalCode"
            type="text"
            value={formValues.postalCode}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                postalCode: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Ville*"
            placeholder="Paris"
            id="city"
            type="text"
            value={formValues.city}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                city: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="SIRET*"
            placeholder="12345678901234"
            id="siret"
            type="text"
            value={formValues.siret}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                siret: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Code NAF*"
            placeholder="1234Z"
            id="codeNaf"
            type="text"
            value={formValues.codeNaf}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                codeNaf: e.target.value,
              })
            }
          />
        </div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Numéro de TVA*"
          placeholder="FR12345678901"
          id="tvaCode"
          type="text"
          value={formValues.tvaCode}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              tvaCode: e.target.value,
            })
          }
        />
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-between">
        <div className="w-full max-w-xs">
          <Button onClick={() => archiveClient(id)} variant="alert">
            Archiver le client
          </Button>
        </div>
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Mettre à jour le client
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpdateClient;
