import { FunctionComponent, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";
import { ReductionType } from "modules/invoice/types/invoice.interface";

interface SelectReductionTypeProps {
  value: ReductionType;
  onChange: (reductionType: ReductionType) => void;
}

export const reductionTypeLabels: Record<ReductionType, string> = {
    [ReductionType.PERCENTAGE]: "%",
    [ReductionType.AMOUNT]: "€",
  };

export const SelectReductionType: FunctionComponent<SelectReductionTypeProps> = ({
  value,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>(reductionTypeLabels[ReductionType.PERCENTAGE]);

  const options: Option[] = Object.values(ReductionType).map((type) => ({
    id: type,
    name: reductionTypeLabels[type],
  }));

  const handleOptionChange = (optionValue: Option) => {
    onChange(optionValue.id as ReductionType);
  };

  if (options.length < 1) return null;

  return (
    <Autocomplete
      options={options}
      onSelect={handleOptionChange}
      value={value ? [value] : []}
      label="Type de réduction*"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};