import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";
import { useGetArticles } from "modules/article/api";

interface SelectArticleProps {
  value: string;
  onChange: (articleId: string) => void;
}

export const SelectArticle: FunctionComponent<SelectArticleProps> = ({
  value,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: articles } = useGetArticles(undefined);
  const flatArticles = articles?.pages?.map((page) => page.data).flat();

  const options = useMemo(() => {
    return (
      flatArticles?.map((article) => ({
        id: article._id,
        name: article.label,
      })) || []
    );
  }, [flatArticles]);

  const handleOptionChange = (optionValue: Option) => {
    onChange(optionValue.id);
  };

  useEffect(() => {
    if (searchValue === "" && value) {
      const article = options.find((option) => option.id === value);
      setSearchValue(article?.name || "");
    }
  }, [value, options, searchValue]);

  if (!options || options.length < 1) return null;

  return (
    <Autocomplete
      options={options}
      onSelect={(option) => handleOptionChange(option)}
      value={value ? [value] : []}
      label="Article*"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
