import { Pagination, SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  Devis,
  CreateDevisPayload,
} from "modules/devis/types/devis.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { DEVIS_ROUTES } from "./devis.api.enum";
import { getDevisBackend } from "./backends";

export const useGetAllDevis = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Devis>> => {
  const setSnack = useSnack();

  const getAllDevis = async ({
    pageParam = 1,
  }): Promise<Pagination<Devis>> => {
    const devisBackend = await getDevisBackend();
    console.log("pageParam", pageParam);
    return await devisBackend.getAllDevis(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    DEVIS_ROUTES.GET_ALL + searchName,
    getAllDevis,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetDevis = (
  id: string | undefined
): UseQueryResult<Devis> => {
  const setSnack = useSnack();

  const getDevis = async (): Promise<Devis> => {
    const DevisBackend = await getDevisBackend();
    return await DevisBackend.getDevis(id);
  };

  return useQueryWithLoading(DEVIS_ROUTES.GET + id, () => getDevis(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateDevis = (): UseMutationResult<
  Devis,
  Error,
  CreateDevisPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.user.user);

  const createDevis = async (
    payload: CreateDevisPayload
  ): Promise<Devis> => {
    const DevisBackend = await getDevisBackend();
    return await DevisBackend.createDevis(user.workspaceId, payload);
  };

  return useMutationWithLoading(
    (payload: CreateDevisPayload) => createDevis(payload),
    {
      onSuccess() {
        setSnack({
          title: "Devis créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(DEVIS_ROUTES.GET_ALL);

        navigate("/admin/devis");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateDevis = (
  id: string | undefined
): UseMutationResult<Devis, Error, CreateDevisPayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateDevis = async (
    payload: CreateDevisPayload
  ): Promise<Devis> => {
    const DevisBackend = await getDevisBackend();
    return await DevisBackend.updateDevis(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateDevisPayload) => updateDevis(payload),
    {
      onSuccess: (data) => {
        setSnack({
          title: "Devis modifié avec succès!",
          severityType: "success",
        });
        
        queryClient.invalidateQueries([DEVIS_ROUTES.GET, id]);
        queryClient.setQueryData([DEVIS_ROUTES.GET, id], data);

        navigate("/admin/devis");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useArchiveDevis = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const archiveDevis = async (id: string): Promise<SuccessResponse> => {
    const DevisBackend = await getDevisBackend();
    return await DevisBackend.archiveDevis(id);
  };

  return useMutationWithLoading((id: string) => archiveDevis(id), {
    onSuccess() {
      setSnack({
        title: "Devis supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(DEVIS_ROUTES.GET_ALL);

      navigate("/admin/devis");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
