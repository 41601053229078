export enum ReductionType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

export enum INVOICE_STATUS {
  DRAFT = 'draft',
  SENT = 'sent',
  PAID = 'paid',
}

export interface InvoiceLineItem {
  articleId?: string;
  description: string;
  quantity: number;
  reductionType?: ReductionType;
  reductionAmount?: number;
  vatAmount?: number;
  total: number;
  priceHT?: number;
}

export interface Invoice {
  _id: string;
  total: number;
  dueDate: Date;
  createdAt: Date;
  updatedAt: Date;
  status: INVOICE_STATUS;
  archived: boolean;
  lineItems: InvoiceLineItem[];
  clientId: string;
  ebpId?: string;
  workspaceId: string;
  interveners?: string[];
  devisId?: string;
}

export interface CreateInvoicePayload {
  _id?: string;
  total: number;
  lineItems: InvoiceLineItem[];
  clientId: string;
  ebpId?: string;
  status?: INVOICE_STATUS;
  archived?: boolean;
  interveners?: string[];
  dueDate: Date;
  articleId?: string;
  reductionType?: ReductionType;
  reductionAmount?: number;
  devisId?: string;
}