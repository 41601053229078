import { Pagination, SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Invoice, CreateInvoicePayload } from "modules/invoice/types/invoice.interface";

export interface invoiceBackend {
  getInvoices(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Invoice>>;
  getInvoice(id: string): Promise<Invoice>;
  createInvoice(
    workspaceId: string,
    payload: CreateInvoicePayload
  ): Promise<Invoice>;
  updateInvoice(id: string, payload: CreateInvoicePayload): Promise<Invoice>;
  archiveInvoice(id: string): Promise<SuccessResponse>;
}

let invoiceBackendInstance: invoiceBackend | undefined;

export async function getInvoiceBackend(): Promise<invoiceBackend> {
  if (invoiceBackendInstance === undefined) {
    const mod = await import(`./${environment.ARTICLE_BACKEND}`);
    invoiceBackendInstance = new mod.default() as invoiceBackend;
  }
  return invoiceBackendInstance;
}
