import { Pagination, SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  Client,
  CreateClientPayload,
  ClientDetails
} from "modules/client/types/client.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { CLIENT_ROUTES } from "./client.api.enum";
import { getClientBackend } from "./backends";

export const useGetClients = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Client>> => {
  const setSnack = useSnack();

  const getClients = async ({
    pageParam = 1,
  }): Promise<Pagination<Client>> => {
    const clientBackend = await getClientBackend();
    console.log("pageParam", pageParam);
    return await clientBackend.getClients(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    CLIENT_ROUTES.GET_ALL + searchName,
    getClients,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetClient = (
  id: string | undefined
): UseQueryResult<Client> => { 
  const setSnack = useSnack();

  const getClient = async (): Promise<Client> => {
    const clientBackend = await getClientBackend();
    const response = await clientBackend.getClient(id) as unknown as SuccessResponse<ClientDetails>;
    return response.data.client; 
  };

  return useQueryWithLoading(CLIENT_ROUTES.GET + id, () => getClient(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateClient = (): UseMutationResult<
  Client,
  Error,
  CreateClientPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.user.user);

  const createClient = async (
    payload: CreateClientPayload
  ): Promise<Client> => {
    const clientBackend = await getClientBackend();
    return await clientBackend.createClient(user.workspaceId, payload);
  };

  return useMutationWithLoading(
    (payload: CreateClientPayload) => createClient(payload),
    {
      onSuccess() {
        setSnack({
          title: "Client créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(CLIENT_ROUTES.GET_ALL);

        navigate("/admin/client");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateClient = (
  id: string | undefined
): UseMutationResult<Client, Error, CreateClientPayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateClient = async (
    payload: CreateClientPayload
  ): Promise<Client> => {
    const clientBackend = await getClientBackend();
    return await clientBackend.updateClient(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateClientPayload) => updateClient(payload),
    {
      onSuccess: (data) => {
        setSnack({
          title: "Client modifié avec succès!",
          severityType: "success",
        });
        
        queryClient.invalidateQueries([CLIENT_ROUTES.GET, id]);
        queryClient.setQueryData([CLIENT_ROUTES.GET, id], data);

        navigate("/admin/client");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useArchiveClient = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const archiveClient = async (id: string): Promise<SuccessResponse> => {
    const clientBackend = await getClientBackend();
    return await clientBackend.archiveClient(id);
  };

  return useMutationWithLoading((id: string) => archiveClient(id), {
    onSuccess() {
      setSnack({
        title: "Client supprimé avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(CLIENT_ROUTES.GET_ALL);

      navigate("/admin/client");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
