import { CreateInvoicePayload } from "modules/invoice/types/invoice.interface";
import { FunctionComponent, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";
import { Devis } from "modules/devis/types/devis.interface";
import { useGetArticles } from "modules/article/api/src/article.api";

type SelectDevisProps = {
  formValues: CreateInvoicePayload;
  setFormValues: (values: CreateInvoicePayload) => void;
  devisList: Devis[];
};

export const SelectDevis: FunctionComponent<SelectDevisProps> = ({
  formValues,
  setFormValues,
  devisList
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: articlesData } = useGetArticles(undefined);

  const options = devisList
    .filter(devis => 
      devis._id.toLowerCase().includes(searchValue.toLowerCase())
    )
    .map(devis => ({
      id: devis._id,
      name: `Devis #${devis._id}`, // Adaptez selon vos besoins
    }));

  const handleOptionChange = (option: Option) => {
    const selectedDevis = devisList.find(d => d._id === option.id);
    if (!selectedDevis) return;

    const allArticles = articlesData?.pages.flatMap(p => p.data) || [];
    
    const updatedLineItems = selectedDevis.lineItems.map(item => ({
      ...item,
      priceHT: allArticles.find(a => a._id === item.articleId)?.priceHT || 0
    }));

    setFormValues({
      ...formValues,
      devisId: selectedDevis._id,
      clientId: selectedDevis.clientId,
      dueDate: new Date(selectedDevis.dueDate),
      interveners: selectedDevis.interveners,
      lineItems: updatedLineItems,
      total: selectedDevis.total
    });
  };

  return (
    <Autocomplete
      options={options}
      onSelect={(option) => handleOptionChange(option)}
      value={[formValues.devisId]}
      label="Sélectionner un devis*"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};