export enum ReductionType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

export enum DEVIS_STATUS {
  DRAFT = 'draft',
  SENT = 'sent',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}

export interface DevisLineItem {
  articleId?: string;
  description: string;
  quantity: number;
  reductionType?: ReductionType;
  reductionAmount?: number;
  vatAmount?: number;
  total: number;
  priceHT?: number;
}

export interface Devis {
  _id: string;
  total: number;
  dueDate: Date;
  createdAt: Date;
  updatedAt: Date;
  status: DEVIS_STATUS;
  archived: boolean;
  lineItems: DevisLineItem[];
  clientId: string;
  ebpId?: string;
  workspaceId: string;
  interveners?: string[];
}

export interface CreateDevisPayload {
  _id?: string;
  total: number;
  lineItems: DevisLineItem[];
  clientId: string;
  ebpId?: string;
  status?: DEVIS_STATUS;
  archived?: boolean;
  interveners?: string[];
  dueDate: Date;
  articleId?: string;
  reductionType?: ReductionType;
  reductionAmount?: number;
}