import { Pagination, SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Client, CreateClientPayload } from "modules/client/types/client.interface";

export interface ClientBackend {
  getClients(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Client>>;
  getClient(id: string): Promise<Client>;
  createClient(
    workspaceId: string,
    payload: CreateClientPayload
  ): Promise<Client>;
  updateClient(id: string, payload: CreateClientPayload): Promise<Client>;
  archiveClient(id: string): Promise<SuccessResponse>;
}

let clientBackendInstance: ClientBackend | undefined;

export async function getClientBackend(): Promise<ClientBackend> {
  if (clientBackendInstance === undefined) {
    const mod = await import(`./${environment.CLIENT_BACKEND}`);
    clientBackendInstance = new mod.default() as ClientBackend;
  }
  return clientBackendInstance;
}
