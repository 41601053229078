import {
  CreateInvoicePayload,
  INVOICE_STATUS,
} from "modules/invoice/types/invoice.interface";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Option } from "components/fields/autoCompleteField";

export interface SelectStatusProps {
  formValues: CreateInvoicePayload;
  setFormValues: (values: CreateInvoicePayload) => void;
}

export const statusLabels: Record<INVOICE_STATUS, string> = {
  [INVOICE_STATUS.DRAFT]: "📝 Brouillon",
  [INVOICE_STATUS.SENT]: "📨 Envoyé",
  [INVOICE_STATUS.PAID]: "💰 Payé",
};

export const SelectStatus: FunctionComponent<SelectStatusProps> = ({
  formValues,
  setFormValues,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const options: Option[] = useMemo(
    () =>
      Object.values(INVOICE_STATUS).map((status) => ({
        id: status,
        name: statusLabels[status],
      })),
    []
  );

  const handleOptionChange = (optionValue: Option) => {
    setFormValues({
      ...formValues,
      status: optionValue.id as INVOICE_STATUS,
    });
  };

  useEffect(() => {
    if (searchValue === "" && formValues.status) {
      const status = options.find((option) => option.id === formValues.status);
      setSearchValue(status?.name || "");
    }
  }, [formValues.status, options, searchValue]);

  if (options.length < 1) return null;

  return (
    <Autocomplete
      options={options}
      onSelect={handleOptionChange}
      value={formValues.status ? [formValues.status] : []}
      label="Sélectionner un statut*"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
